import { Dispatch, SetStateAction, useState } from 'react';
import { UrlObject } from 'url';

import { QueryParams } from '@keaze/web/common/enums';
import { OnChangeTab, OnClickListItem, TabsValue } from './quickSearch.types';

type UseQuickSearchExpected = {
  tabValue: TabsValue;
  googleSearchValue: string;
  setGoogleSearchValue: Dispatch<SetStateAction<string>>;
  generatedHref: UrlObject;
  onChangeTab: OnChangeTab;
  onClickListItem: OnClickListItem;
};

type UseQuickSearch = () => UseQuickSearchExpected;

export const useQuickSearch: UseQuickSearch = () => {
  const [tabValue, setTabValue] = useState(TabsValue.ForSale);
  const [googleSearchValue, setGoogleSearchValue] = useState<string>('');

  const defaultQuery = {
    [QueryParams.OccupancyType]: tabValue,
  };

  const generateQuery =
    googleSearchValue === ''
      ? defaultQuery
      : { ...defaultQuery, [QueryParams.Location]: googleSearchValue };

  const generatedHref: UrlObject = {
    pathname: '/search',
    query: generateQuery,
  };

  const handleChangeTab: OnChangeTab = (value) => setTabValue(value);

  const handleClickListItem: OnClickListItem = (value) =>
    setGoogleSearchValue(value);

  return {
    tabValue,
    googleSearchValue,
    setGoogleSearchValue,
    generatedHref,
    onChangeTab: handleChangeTab,
    onClickListItem: handleClickListItem,
  };
};
