import { OccupancyType } from '@keaze/web/common/enums';

export enum TabsValue {
  ForSale = OccupancyType.Sale,
  ToRent = OccupancyType.Rent,
}

export type OnChangeTab = (value: TabsValue) => void;

export type OnClickListItem = (value: string) => void;
