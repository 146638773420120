import React, { memo, useMemo } from 'react';
import NextLink from 'next/link';
import { UrlObject } from 'url';

import { useLocalStorage } from '@keaze/web/hooks/useLocalStorage';
import {
  LocalStorageKeys,
  OccupancyType,
  QueryParams,
} from '@keaze/web/common/enums';
import { PersonalizedSearchForm } from '@keaze/web/common/interfaces';
import {
  HomeHero,
  HomeHeroGrid,
  HomeHeroButton,
  HeroImage,
  Title,
  TitlePart,
  TitlePartAccented,
  Text,
  GridText,
  ButtonWrapper,
} from './homeHeroFirst.styles';

const PERSONAL_INFORMATION_PATH = '/personal-information';

export const HomeHeroFirst = memo(() => {
  const [
    storedPersonalisedSearchData,
  ] = useLocalStorage<PersonalizedSearchForm | null>(
    LocalStorageKeys.personalizedSearch,
    null
  );

  const searchPath: UrlObject = useMemo(
    () => ({
      pathname: '/search',
      query: {
        [QueryParams.OccupancyType]: OccupancyType.Sale,
      },
    }),
    []
  );

  const linkHref = useMemo(
    () =>
      storedPersonalisedSearchData === null
        ? PERSONAL_INFORMATION_PATH
        : searchPath,
    [storedPersonalisedSearchData, searchPath]
  );

  return (
    <HomeHero>
      <HeroImage />
      <HomeHeroGrid container alignItems="stretch" justify="space-between">
        <GridText item xs={12} sm={9}>
          <Title variant="h1">
            <TitlePart>We’re making</TitlePart>
            <TitlePartAccented>home hunting easier</TitlePartAccented>
          </Title>
          <Text variant="body1">
            The smart way to search for a new home to get you moving faster
          </Text>
          <ButtonWrapper>
            <NextLink href={linkHref} prefetch={false} passHref>
              <HomeHeroButton
                color="secondary"
                variant="contained"
                size="large"
              >
                Get started
              </HomeHeroButton>
            </NextLink>
          </ButtonWrapper>
        </GridText>
      </HomeHeroGrid>
    </HomeHero>
  );
});
