import React, { FC, memo } from 'react';
import Grid from '@mui/material/Grid';

import { StyledSection } from './searchBlocks.styles';
import { PersonalisedSearch } from './personalisedSearch';
import { QuickSearch } from './quickSearch';

export const SearchBlocks: FC = memo(() => (
  <StyledSection position="topRight">
    <Grid container>
      <Grid item xs={12} lg={6}>
        <PersonalisedSearch />
      </Grid>
      <Grid item xs={12} lg={6}>
        <QuickSearch />
      </Grid>
    </Grid>
  </StyledSection>
));
