import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export const HomeHero = styled(Box)`
  ${({ theme: { breakpoints, sizes } }) => `   
    margin-top: -${sizes.headerHeight.default};
    position: relative;
    ${breakpoints.down('sm')} {
      margin-top: -${sizes.headerHeight.sm};
    }
  `}
`;

export const HomeHeroGrid = styled(Grid)`
  ${({ theme: { breakpoints } }) => `
    position: relative;
    z-index: 2;
    min-height: 550px;
    ${breakpoints.up('md')} {
      min-height: 670px;
    }   
  `}
`;

export const Title = styled(Typography)`
  ${({ theme: { typography, breakpoints } }) => `
    font-size: ${typography.pxToRem(50)};
    line-height: 1.2;
    margin: 0 0 0.3em;
    ${breakpoints.down('sm')} {
      font-size: 45px;
    }
    ${breakpoints.down('xs')} {
      font-size: ${typography.pxToRem(40)};
    }
  `}
`;

export const TitlePart = styled.span`
  ${({
    theme: {
      palette,
      typography: { pxToRem },
      breakpoints,
    },
  }) => `
    display: block; 
    color: ${palette.primary.main};
    font-size: ${pxToRem(40)};
    
    ${breakpoints.up('md')} {
      font-size: ${pxToRem(50)};
    }
  `}
`;

export const TitlePartAccented = styled.span`
  ${({
    theme: {
      palette,
      typography: { pxToRem },
      breakpoints,
    },
  }) => `
    display: block;
    color: ${palette.secondary.main};
    font-size: ${pxToRem(40)};
    
    ${breakpoints.up('md')} {
      font-size: ${pxToRem(42)};
    }
  `}
`;

export const Text = styled(Typography)`
  ${({ theme: { breakpoints, palette, typography } }) => `
    font-weight: 400;
    font-size: ${typography.pxToRem(24)};
    line-height: 1.5;
    color: ${palette.text.primary};
    margin: 0;
    max-width: 18em;
    ${breakpoints.down('xs')} {
      font-size: ${typography.pxToRem(20)};
      max-width: 70%;
    }
  `}
`;

export const ButtonWrapper = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    margin-top: 60px;
    ${breakpoints.down('xs')} {
      margin-top: 30px;
    }
  `}
`;

export const HomeHeroButton = styled(Button)`
  ${({ theme: { breakpoints, typography } }) => `
    font-size: ${typography.h3.fontSize};
    width: 100%;
    height: 60px;

    ${breakpoints.up('sm')} {
      width: 280px;
    }
  `}
`;

export const GridText = styled(Grid)`
  ${({ theme: { breakpoints } }) => `
    align-self: center;
    ${breakpoints.down('xs')} {
      padding: 100px 0 60px;
    }
  `}
`;

export const HeroImage = styled(Box)`
  ${({ theme: { breakpoints } }) => `    
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    z-index: 1;
    background-image: url("/images/hero-first-mobile.jpg");
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;

    ${breakpoints.up('sm')} {
      bottom: -15%;
      background-image: url("/images/hero-first.jpg");
      background-position: top left;
    }

    ${breakpoints.between('sm', 'sm')} {
      opacity: 0.8;
    }

    ${breakpoints.up('lg')} {
      bottom: -35%;
    }
  `}
`;
