import React, { FC } from 'react';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';

import { Head } from '@keaze/web/components/head';
import { getHomeFeaturedListings } from '../api/homeFeaturedListings';
import { getFeaturedHousingProviders } from '../api/featuredHousingProviders';
import { getSchemeAreas } from '../api/schemeAreas/getSchemeAreas';
import {
  FeaturedHousingProvider,
  SchemeArea,
  Listing,
  RequestError,
} from '../common/interfaces';
import { SchemeAreasProps } from '@keaze/web/views/schemeAreas';
import { FeaturedHousingProvidersProps } from '@keaze/web/views/featuredHousingProviders';
import { FeaturedPropertiesProps } from '@keaze/web/views/featuredProperties';
import { HomeHeroFirst } from '@keaze/web/views/homeHero/homeHeroFirst';
import { SearchBlocks } from '@keaze/web/views/searchBlocks';

const REVALIDATE_IN_SECONDS = 60 * 60 * 6;

const DynamicHomeHeroSecond = dynamic<unknown>(() =>
  import('@keaze/web/views/homeHero/homeHeroSecond').then(
    (model) => model.HomeHeroSecond
  )
);

const DynamicFeaturedProperties = dynamic<FeaturedPropertiesProps>(() =>
  import('@keaze/web/views/featuredProperties').then(
    (model) => model.FeaturedProperties
  )
);

const DynamicFeaturedHousingProviders = dynamic<FeaturedHousingProvidersProps>(
  () =>
    import('@keaze/web/views/featuredHousingProviders').then(
      (model) => model.FeaturedHousingProviders
    )
);

const DynamicSchemeAreas = dynamic<SchemeAreasProps>(() =>
  import('@keaze/web/views/schemeAreas').then((model) => model.SchemeAreas)
);

type Props = {
  featuredListings: Listing[];
  featuredHousingProviders: FeaturedHousingProvider[];
  schemeAreas: SchemeArea[];
};

const IndexPage: FC<Props> = ({
  featuredListings,
  featuredHousingProviders,
  schemeAreas,
}) => (
  <>
    <Head
      withBaseSocialMetaTags
      title="Search Help to Buy, Shared Ownership & Rent to Buy properties | Keaze"
    >
      <meta
        name="description"
        content="Search and book an appointment to view new homes for sale with Shared ownership, Help to Buy and London Living Rent."
      />
      <meta
        name="keywords"
        content="Shared Ownership, Help to Buy, Property Events, Open days"
      />
    </Head>
    <HomeHeroFirst />
    <SearchBlocks />
    <DynamicHomeHeroSecond />
    <DynamicFeaturedProperties data={featuredListings} />
    <DynamicFeaturedHousingProviders data={featuredHousingProviders} />
    <DynamicSchemeAreas data={schemeAreas} />
  </>
);

export const getStaticProps: GetStaticProps = async () => {
  try {
    const [
      featuredListings,
      featuredHousingProviders,
      schemeAreas,
    ] = await Promise.all([
      getHomeFeaturedListings(),
      getFeaturedHousingProviders(),
      getSchemeAreas(),
    ]);

    return {
      revalidate: REVALIDATE_IN_SECONDS,
      props: {
        featuredListings,
        featuredHousingProviders,
        schemeAreas,
      },
    };
  } catch (error) {
    const { message, notFound } = error as RequestError;

    if (notFound) {
      return {
        notFound: true,
      };
    }

    throw new Error(message);
  }
};

export default IndexPage;
