import { FC, memo, useCallback, useContext, useMemo } from 'react';
import NextLink from 'next/link';
import Typography from '@material-ui/core/Typography';
import { UrlObject } from 'url';

import { Header } from '../header';
import {
  StyledPersonalizedSearch,
  List,
  ListItem,
  GetResultsButton,
} from './personalisedSearch.styles';
import { useLocalStorage } from '@keaze/web/hooks';
import {
  LocalStorageKeys,
  QueryParams,
  OccupancyType,
} from '@keaze/web/common/enums';
import { PersonalizedSearchForm } from '@keaze/web/common/interfaces';
import { GTMabTestPersonalizedSearch } from '@keaze/web/libs/GTM';
import { StoreContext } from '@keaze/web/store';
import { setABTestPersonalizedSearch } from '@keaze/web/store/abTest';

const PERSONAL_INFORMATION_PATH = '/personal-information';

const SEARCH_PATH: UrlObject = {
  pathname: '/search',
  query: {
    [QueryParams.OccupancyType]: OccupancyType.Sale,
  },
};

const PersonalizedSearchLogo = () => (
  <svg
    width="90"
    height="74"
    viewBox="0 0 90 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="62"
      height="74"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62 0H0V74H62V0ZM14.9775 63H4V73.9775L14.9775 63Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 32C38.732 32 45 25.732 45 18C45 10.268 38.732 4 31 4C23.268 4 17 10.268 17 18C17 25.732 23.268 32 31 32ZM31 36C40.9411 36 49 27.9411 49 18C49 8.05887 40.9411 0 31 0C21.0589 0 13 8.05887 13 18C13 27.9411 21.0589 36 31 36Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 65V63C58 48.0883 45.9117 36 31 36C16.0883 36 4 48.0883 4 63V65H58ZM31 32C13.8792 32 0 45.8792 0 63V69H62V63C62 45.8792 48.1208 32 31 32Z"
        fill="currentColor"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.416 22C67.4732 21.8692 67.5358 21.7414 67.6035 21.6167C68.4502 20.0581 70.1015 19 72 19C74.0503 19 75.8124 20.2341 76.584 22H90V26H76.584C76.5268 26.1308 76.4642 26.2586 76.3965 26.3833C75.5498 27.9419 73.8985 29 72 29C69.9497 29 68.1876 27.7659 67.416 26H64V22H67.416ZM77.416 36H64.001V40H77.416C78.1876 41.7659 79.9497 43 82 43C83.8985 43 85.5498 41.9419 86.3965 40.3833C86.4642 40.2586 86.5268 40.1308 86.584 40H90.001V36H86.584C85.8124 34.2341 84.0503 33 82 33C80.1015 33 78.4502 34.0581 77.6035 35.6167C77.5358 35.7414 77.4732 35.8692 77.416 36ZM83.0927 38L82.9185 37.6015C82.7803 37.2851 82.4899 37.0619 82.1489 37.011C82.1002 37.0037 82.0505 37 82 37C81.9296 37 81.8607 37.0073 81.794 37.0212C81.4779 37.0873 81.2119 37.3029 81.0815 37.6015L80.9073 38L81.0815 38.3985C81.2197 38.7149 81.5101 38.9381 81.8511 38.989C81.8998 38.9963 81.9495 39 82 39C82.0707 39 82.1399 38.9926 82.2069 38.9786C82.5226 38.9123 82.7882 38.6968 82.9185 38.3985L83.0927 38ZM70.9073 24L71.0815 24.3985C71.2197 24.7149 71.5101 24.9381 71.8511 24.989C71.8998 24.9963 71.9495 25 72 25C72.0628 25 72.1244 24.9942 72.1843 24.9831C72.5099 24.9225 72.7851 24.7039 72.9185 24.3985L73.0927 24L72.9185 23.6015C72.7803 23.2851 72.4899 23.0619 72.1489 23.011C72.1002 23.0037 72.0505 23 72 23C71.9423 23 71.8856 23.0049 71.8303 23.0143C71.4983 23.071 71.2169 23.2916 71.0815 23.6015L70.9073 24Z"
      fill="currentColor"
    />
  </svg>
);

export const PersonalisedSearch: FC = memo(() => {
  const { dispatch } = useContext(StoreContext);

  const [
    storedPersonalizedSearchData,
  ] = useLocalStorage<PersonalizedSearchForm | null>(
    LocalStorageKeys.personalizedSearch,
    null
  );

  const linkHref = useMemo(
    () =>
      storedPersonalizedSearchData === null
        ? PERSONAL_INFORMATION_PATH
        : SEARCH_PATH,
    [storedPersonalizedSearchData]
  );

  const handlePersonalizedButtonClick = useCallback(() => {
    if (storedPersonalizedSearchData === null) {
      dispatch(setABTestPersonalizedSearch('landing'));
      GTMabTestPersonalizedSearch('view_eligibility_params_form', 'landing');
    }
  }, [storedPersonalizedSearchData, dispatch]);

  return (
    <StyledPersonalizedSearch>
      <Header
        title="Personalised search"
        subtitle="Get tailored results based on your requirements, needs and circumstances:"
        icon={{
          component: <PersonalizedSearchLogo />,
          color: 'secondary',
        }}
      />
      <List disablePadding>
        <ListItem disableGutters>
          <Typography variant="body2">Your eligibility</Typography>
        </ListItem>
        <ListItem disableGutters>
          <Typography variant="body2">Where you want to live</Typography>
        </ListItem>
        <ListItem disableGutters>
          <Typography variant="body2">
            What you potentially could afford
          </Typography>
        </ListItem>
      </List>
      <GetResultsButton
        variant="contained"
        color="secondary"
        onClick={handlePersonalizedButtonClick}
      >
        <NextLink href={linkHref} prefetch={false} passHref>
          <a>Get personalised results</a>
        </NextLink>
      </GetResultsButton>
    </StyledPersonalizedSearch>
  );
});
