import { FC, memo } from 'react';
import NextLink from 'next/link';

import { TabData } from '@keaze/web/components/tabs';
import { GoogleSearchAutocomplete } from '@keaze/web/components/googleSearchAutocomplete';
import { Header } from '../header';
import {
  StyledQuickSearch,
  List,
  ListItem,
  AreaButton,
  Tabs,
  QuickLookButton,
} from './quickSearch.styles';
import { OnClickListItem, TabsValue } from './quickSearch.types';
import { useQuickSearch } from './quickSearch.hook';

const LIST: string[] = ['London', 'Essex', 'Kent', 'Hertfordshire', 'Croydon'];

type RenderListItemArgs = {
  value: string;
  isLast: boolean;
  onClick: () => void;
};

type RenderListItem = (args: RenderListItemArgs) => JSX.Element;

type RenderList = (
  data: string[],
  onClickListItem: OnClickListItem
) => JSX.Element;

const QuickSearchLogo = () => (
  <svg
    width="75"
    height="81"
    viewBox="0 0 75 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="70"
      height="76"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H53V40.0273C52.669 40.0092 52.3356 40 52 40C42.0589 40 34 48.0589 34 58C34 65.8631 39.0418 72.5486 46.069 75H0V0ZM46.069 75C47.9262 75.6479 49.922 76 52 76C61.9411 76 70 67.9411 70 58C70 48.3944 62.476 40.5462 53 40.0273V75H46.069ZM4 64H14.9775L4 74.9775V64Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 28.167L26.5 5.65799L4 28.167V66L49 66V28.167ZM53 70L0 70V26.5106L26.5 0L53 26.5106V70Z"
        fill="currentColor"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 72C59.732 72 66 65.732 66 58C66 50.268 59.732 44 52 44C44.268 44 38 50.268 38 58C38 65.732 44.268 72 52 72ZM52 76C61.9411 76 70 67.9411 70 58C70 48.0589 61.9411 40 52 40C42.0589 40 34 48.0589 34 58C34 67.9411 42.0589 76 52 76Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.1463 80.8588L61.1463 69.8588L63.9747 67.0304L74.9747 78.0304L72.1463 80.8588Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 21.5L6 10L10 10L10 21.5L6 21.5Z"
      fill="currentColor"
    />
  </svg>
);

const renderListItem: RenderListItem = ({ value, isLast, onClick }) => (
  <ListItem key={value} disableGutters>
    <AreaButton variant="text" color="primary" onClick={onClick}>
      {value}
      {isLast ? '' : ','}
    </AreaButton>
  </ListItem>
);

const renderList: RenderList = (data, onClickListItem) => (
  <List disablePadding>
    {data.map((value, index) => {
      const isLast = data.length - 1 === index;
      const onClick = () => onClickListItem(value);

      return renderListItem({ value, isLast, onClick });
    })}
  </List>
);

export const QuickSearch: FC = memo(() => {
  const {
    tabValue,
    googleSearchValue,
    setGoogleSearchValue,
    generatedHref,
    onChangeTab,
    onClickListItem,
  } = useQuickSearch();

  const tabsData: TabData<TabsValue>[] = [
    { label: 'For sale', value: TabsValue.ForSale },
    { label: 'To rent', value: TabsValue.ToRent },
  ];

  return (
    <StyledQuickSearch>
      <Header
        title="Quick search"
        subtitle="Look at homes where you want to live"
        icon={{
          component: <QuickSearchLogo />,
          color: 'primary',
        }}
      />
      <Tabs
        value={tabValue}
        data={tabsData}
        ariaLabel="quick search tabs"
        onChange={onChangeTab}
      />
      <GoogleSearchAutocomplete
        value={googleSearchValue}
        onChange={(value) => setGoogleSearchValue(value)}
      />
      {renderList(LIST, onClickListItem)}
      <QuickLookButton variant="contained" color="primary">
        <NextLink href={generatedHref} prefetch={false} passHref>
          <a>Quick look at properties</a>
        </NextLink>
      </QuickLookButton>
    </StyledQuickSearch>
  );
});
