import styled from 'styled-components';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export const StyledPersonalizedSearch = styled(Box)`
  ${({ theme: { colors, breakpoints } }) => `
    position: relative;
    padding: 30px 0;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 50vw;
      top: 0;
      bottom: 0;
      background: ${colors.greys.greys2};
      z-index: -1;

      ${breakpoints.down('md')} {
        left: -30px;
        right: -30px;
        width: auto;
      }
    }

    ${breakpoints.up('lg')} {
      padding: 55px 100px 55px 0;
    }
  `}
`;

export const List = styled(MUIList)`
  ${({ theme: { colors, breakpoints } }) => `
    margin: 25px 0;
    padding-top: 25px;
    border-top: 2px solid ${colors.greys.greys15};

    ${breakpoints.up('md')} {
      margin: 27px 0 35px;
    }
  `}
`;

export const ListItem: any = styled(MUIListItem)`
  ${({ theme: { palette } }) => `
    position: relative;
    padding: 0 0 0 20px;
    margin-bottom: 7px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      transform: translateY(-50%);
      background-color: ${palette.text.primary};
      opacity: 0.3;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

export const GetResultsButton = styled(Button)`
  ${({ theme: { breakpoints } }) => `
    width: 100%;

    ${breakpoints.up('lg')} {
      width: auto;
    }
  `}
`;
