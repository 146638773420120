import { FC, ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';

import { HeaderGrid, Title, Subtitle, IconWrapper } from './header.styles';

type Icon = {
  component: ReactNode;
  color: string;
};

type Props = {
  title: string;
  subtitle: string;
  icon: Icon;
};

export const Header: FC<Props> = ({ title, subtitle, icon }) => (
  <HeaderGrid container wrap="nowrap" justify="space-between">
    <Grid item>
      <Title variant="h1" color="textPrimary">
        {title}
      </Title>
      <Subtitle variant="body2" color="textSecondary">
        {subtitle}
      </Subtitle>
    </Grid>
    <Grid item>
      <IconWrapper $color={icon.color}>{icon.component}</IconWrapper>
    </Grid>
  </HeaderGrid>
);
