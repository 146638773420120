import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box, { BoxProps } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { WithMyTheme } from '@keaze/web/theme';

interface IconWrapperProps extends WithMyTheme {
  $color: string;
}

export const HeaderGrid = styled(Grid)`
  ${({ theme: { breakpoints } }) => `
    ${breakpoints.up('md')} {
      min-height: 85px;
    }
  `}
`;

export const IconWrapper = styled(Box)<BoxProps & IconWrapperProps>`
  ${({ theme: { palette }, $color }: IconWrapperProps) => `
    margin-left: 10px;
    color: ${palette[$color].main};

  `}
`;

export const Title = styled(Typography)`
  ${({
    theme: {
      typography,
      breakpoints,
      typography: { pxToRem },
    },
  }) => `
    margin-bottom: 5px;
    line-height: ${pxToRem(32)};
    flex: 1 1 auto;

    ${breakpoints.up('sm')} {
      font-size: ${typography.pxToRem(30)};
    }
  `}
`;

export const Subtitle = styled(Typography)`
  ${({ theme: { colors } }) => `
    line-height: 1.7;
    color: ${colors.greys.greys13};
    max-width: 21em;
  `}
`;
