import styled from 'styled-components';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIButton from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { Tabs as TabsComponent } from '@keaze/web/components';

export const StyledQuickSearch = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    padding: 30px 0;

    ${breakpoints.up('lg')} {
      padding: 55px 0 55px 100px;
    }
  `}
`;

export const List = styled(MUIList)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 8px 0 31px;
`;

export const ListItem: any = styled(MUIListItem)`
  ${({ theme: { palette } }) => `
    width: auto;
    color: ${palette.primary.main};
    padding: 0;
    margin-right: 10px;
  `}
`;

export const AreaButton = styled(MUIButton)`
  ${({
    theme: {
      typography: { pxToRem },
    },
  }) => `
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(17)};
    font-weight: 500;
    padding: 0;
    min-width: auto;
  `}
`;

export const Tabs: any = styled(TabsComponent)`
  ${({ theme: { breakpoints } }) => `
    margin-bottom: 20px;

    ${breakpoints.up('md')} {
      margin-top: -13px;
      margin-bottom: 34px;
    }
  `}
`;

export const QuickLookButton = styled(MUIButton)`
  ${({ theme: { breakpoints } }) => `
    width: 100%;

    ${breakpoints.up('lg')} {
      width: auto;
    }
  `}
`;
